import './global-styles.css'; // Load css first as menu js relies on css styling to render correctly
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { configureStore } from './config/configureStore';
import { configureI18n } from './config/configureI18n';
import { ProsperUsContext } from './hooks/ProsperUsContext';
import { HasCoachMarksProvider } from './hooks/useHasCoachMarks';
import App from './App';
import { loadConfig } from './loadConfig';
import { } from './typings/app.d';

import { DateTimeService, registerLocaleLoader } from '@staizen/graphene';
import './config/configureTheme';
import { getI18n } from 'react-i18next';
// Setting up PWA elements for web-based UI of some native functions. Ref: https://capacitorjs.com/docs/web/pwa-elements
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import retry from './utils/retry';
import { PrivacyScreen } from '@capacitor-community/privacy-screen';
import { Capacitor } from '@capacitor/core';
import { defineCustomElements as defineGraphene, applyPolyfills } from '@staizen/graphene/loader';

applyPolyfills().then(() => defineGraphene());

// Explicitly declaring DateTimeService's defaultZoneName to prevent incorrect parsing in Safari
// For more info, view README.md: Known Issues -> Inaccurate parsing of date in Safari
const { DateTime, Settings: DateTimeServiceSettings } = DateTimeService;
DateTimeServiceSettings.defaultZoneName = DateTime.local().zoneName;

const token = process.env.NODE_ENV === 'production' ? 'dev' : process.env.REACT_APP_ENV || 'loc'; // override loc to force an environment config
loadConfig(token).then(async (module: ConfigModule) => {
  await configureI18n(module.config.fallbackLocale as string);
  registerLocaleLoader('app', (locale: string) => retry(() => import(`./locales/locale.${locale}.json`)));
  const i18n = getI18n();
  await i18n.loadNamespaces('app');
  const store = configureStore({ config: module.config });
  if (Capacitor.getPlatform() === 'android') {
    // Browserstack doesnt work with android FLAG_SECURE
    // No environment check as  PrivacyScreen is broken on Android anyway
    await PrivacyScreen.disable();
  }

  ReactDOM.render(
    <ProsperUsContext store={store}>
      <HasCoachMarksProvider>
        <App />
      </HasCoachMarksProvider>
    </ProsperUsContext>,
    document.getElementById('root'),
  );

  // PWA Elements: Call the element loader after the app has been rendered the first time
  defineCustomElements(window);
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls. See here: https://create-react-app.dev/docs/making-a-progressive-web-app/#offline-first-considerations
serviceWorker.unregister();
