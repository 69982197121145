import { i18n, setTheme } from '@staizen/graphene';
import React, { lazy, Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageVisibility from 'react-page-visibility';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import 'scroll-behavior-polyfill'; // polyfill for scrollIntoView options
import semver from 'semver';
import project from '../package.json';
import { getToken } from './actions/creators/auth';
import { getUserParticulars, loadAllUserPreferences, PreferenceType, saveAndSetPreferences, UserStore } from './actions/creators/user';
import SessionTimeoutDialog from './components/Dialogs/SessionTimeoutDialog';
import { Capacitor } from '@capacitor/core';
import ScrollToTop from './components/ScrollToTop';
import routes from './config/configureRoutes';
import { persistor } from './config/configureStore';
import useVirtualKeyboardListener from './hooks/useVirtualKeyboardListener';
import { deleteCookie, getCookie } from './utils/cookie';
import { getDefaultTheme } from './utils/theme';
import retry from './utils/retry';
import ClickInterceptor from './components/Router/ClickInterceptor';
import { generateMenu } from './config/configureMenu';
import { setMenu } from './actions/creators/menu';
import { generateSsoLink, pingSessionAlive } from './api/auth';
import saveUserPreference from './api/userPreferences/saveUserPreference';
import { clearDismissedBannerAlerts } from './actions/creators/meta';
import { getMinVersionAndroid } from './api/updates/android';
import { getMinVersionIOS } from './api/updates/ios';
import { SplashScreen } from '@capacitor/splash-screen';
import AppBlocker from './components/AppBlocker/AppBlocker';
import useAppBlocker from './hooks/useAppBlocker';

// For testing
// import saveUserPreference, { resetUserPreferences } from './api/userPreferences/saveUserPreference';
// import { getQueryParams } from '@staizen/utils/dist/URLUtils';

const Login = lazy(() => retry(() => import('./templates/Login/Login')));
const RequiredUpdate = React.lazy(() => import('./components/UpdateVersionMessage/RequiredUpdate'));
const NonRequiredUpdate = React.lazy(() => import('./components/UpdateVersionMessage/NonRequiredUpdate'));
const platform = Capacitor.getPlatform(); // Get the platform name (e.g., "android", "ios");
const appVersion = project?.version || '';

const SPLASH_SCREEN_HIDE_DELAY = 3000;

function App() {
  const { appAllowed } = useAppBlocker();
  const [showLogin, setShowLogin] = useState(false);
  const { t } = useTranslation('app');
  const splashScreenTimeoutRef = useRef(null);
  const [showRequireUpdate, setShowRequireUpdate] = useState(false);
  const [showNonRequireUpdate, setShowNonRequireUpdate] = useState(false);

// Handle the dismissal of the update message (once per week)
const handleDismiss = (): void => {
  const now = new Date(); // Save the dismissal time locally
  localStorage.setItem('dismissedTime', now.toISOString());
  setShowNonRequireUpdate(false);
};

// Check the last dismissed time from local storage
const checkLastDismissedTime = (): void => {
  const lastDismissedTime = localStorage.getItem('dismissedTime');
  if (!lastDismissedTime) {
    setShowNonRequireUpdate(true); // Show the message if there is no dismissal time
    return;
  }

  const dismissedTimestamp = new Date(lastDismissedTime).getTime();
  const currentTime = new Date().getTime();
  const oneWeekMilliseconds = 7 * 24 * 60 * 60 * 1000;

  // Determine if the message should be shown based on expiration
  const shouldShowMessageAgain = currentTime - dismissedTimestamp >= oneWeekMilliseconds;

  // If more than a week has passed since the last dismissal, show the component again
  if (shouldShowMessageAgain) {
    setShowNonRequireUpdate(true);
    localStorage.removeItem('dismissedTime');
  }
};

useEffect(() => {
  checkLastDismissedTime();
}, []);

useEffect(() => {
  const checkAndUpdateUI = async (): Promise<void> => {
    const lastDismissedTime = localStorage.getItem('dismissedTime');
    const dismissedTimestamp = lastDismissedTime ? new Date(lastDismissedTime).getTime() : null;
    const currentTime = new Date().getTime();
    const oneWeekMilliseconds = 7 * 24 * 60 * 60 * 1000;
    const isMoreThanAWeekAgo = dismissedTimestamp ? currentTime - dismissedTimestamp >= oneWeekMilliseconds : false;

    let response;
    let requireUpdate = false;
    let nonRequireUpdate = false;

    if (platform === 'android' || platform === 'ios') {
      // Make the API call based on the platform
      if (platform === 'android') {
        response = await getMinVersionAndroid();
      } else if (platform === 'ios') {
        response = await getMinVersionIOS();
      }

      if (response && response.error) {
        console.error('Error on response:', response.error);
        return;
      }

      const { minimumVersion } = response.data;

      // Checking if the appVersion is 1.2.0
      const isVersion120 = semver.satisfies(appVersion, '1.2.0');
      const shouldShowNonRequireUpdate = isVersion120 && (!lastDismissedTime || isMoreThanAWeekAgo);

      if (!isVersion120 && semver.lt(appVersion, minimumVersion)) {
        requireUpdate = true;
      } else if (shouldShowNonRequireUpdate) {
        nonRequireUpdate = true;
      }
    } else if (platform === 'web') {
      console.log('Running on web platform');
    }

    setShowRequireUpdate(requireUpdate);
    setShowNonRequireUpdate(nonRequireUpdate);
  };

  checkAndUpdateUI();
}, [appVersion]);

  useEffect(() => {
    const menu = generateMenu((key: string, options?: { [key: string]: any }) => i18n.t(`app:menu.titles.${key}`, options));
    dispatch(setMenu(menu));
  }, [t]);

  useVirtualKeyboardListener();

  // mock for testing cookie without SSOPortal to set cookie
  // document.cookie = 'sso=a4bbc2a0f1671e8dad762db080b457338938edb8c1cde3b62e8e200b27be3732; Max-Age=3600; Domain=.prosperus.asia; Secure; SameSite=None';

  const performSessionCheckOnVisible = (isVisible: boolean) => {
    if (isVisible) {
      pingSessionAlive();
    }
  };

  // fetch token
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const store = useStore();
  const { config } = store.getState();
  // eslint-disable-next-line no-underscore-dangle
  const loadedAuthFromStorage = useSelector((state) => state.auth._persist?.rehydrated);
  useEffect(() => {
    const { cookies: { sso: ssoCookieConfig } } = config;
    const sso = getCookie(ssoCookieConfig.key);
    if (sso) { // User has sso token, get session token
      deleteCookie(ssoCookieConfig.key, ssoCookieConfig.path, ssoCookieConfig.domain); // prevent reuse of one time sso
      performLogin(sso);
    } else if (!authState.data?.accessToken) { // User does not have sso and also no token
      persistor.persist(); // load authState (token) from storage
    }

    splashScreenTimeoutRef.current = setTimeout(() => {
      // Hide splash screen after delay to minimise blank screen time while page load
      SplashScreen.hide();
      splashScreenTimeoutRef.current = null;
    }, SPLASH_SCREEN_HIDE_DELAY);

    return () => {
      // Clear timeout when component unmounts if timeout is still active
      if (splashScreenTimeoutRef.current) {
        clearTimeout(splashScreenTimeoutRef.current);
      }
    };
  }, []);

  const performLogin = async (token: string) => {
    await dispatch(getToken(token));
    await dispatch(clearDismissedBannerAlerts(true));
  };

  useEffect(() => {
    if (authState.data?.accessToken
      && authState.ui?.isError === false && authState.ui?.isFetching === false) { // Known issue - token data is stale (it has been deleted but useEffect assumes token is still here) so may call pingSession alive without auth headers, in dev this returns 200 and does not cause an issue
      // User is already signed in, retrieve particulars to get back name and userID
      // setAppLoaded(true);
      console.log('APP LOADED', !!authState.data?.accessToken);
      pingSessionAlive()
        .then(() => dispatch(getUserParticulars())) // creates user store
        .then(() => dispatch(loadAllUserPreferences())) // requires userStore to set prefs
        .then(() => {
          setShowLogin(false);
          persistor.persist(); //   also load the userPrefs after retrieving userID from userparticulars
        });
      return;
    }
    if (!authState.data?.accessToken && !authState.ui?.isFetching && loadedAuthFromStorage) { // still no token despite loading storage
      if (config.useMock) {
        performLogin('mockToken');
      } else if (Capacitor.isNativePlatform() || (config.features.alpha && config.environment === 'DEV')) {
        setShowLogin(true);
      } else {
        window.location.href = generateSsoLink();
      }
    }
  }, [authState.data?.accessToken, loadedAuthFromStorage]);

  const prefs = useSelector((state: any) => {
    return (state.user && state.user.preferences) || {};
  });

  const hasPreferencesLoaded = useSelector((state: any) => {
    return state.meta?.preferences?.loadedPreferences;
  });

  const particulars = useSelector((state: any) => {
    return (state.user && state.user.particulars?.data) || null;
  });

  const isUserStorePersisted = useSelector((state) => {
    return state?.user?._persist?.rehydrated;
  });

  const [isLanguageLoaded, setIsLanguageLoaded] = useState(false);
  const loadLanguage = async () => {
    if (prefs.language) {
      await i18n.changeLanguage(prefs.language);
    }
    setIsLanguageLoaded(true);
  };

  const migratePreferences = async (user: UserStore) => {
    const prefsDefaults = {
      theme: getDefaultTheme(),
      language: 'en',
    } as any;

    await dispatch(saveAndSetPreferences(Object.assign(prefsDefaults, user.preferences))); // an update is required if defaults are used
    await saveUserPreference({ key: PreferenceType.Dashboards });
    await saveUserPreference({ key: PreferenceType.DismissedPrompts });
    await saveUserPreference({ key: PreferenceType.CoachMark });
  };

  // Listen for changes in theme prefs
  useEffect(() => {
    if (prefs.theme) {
      setTheme(prefs.theme);
    }
  }, [prefs.theme]);

  useEffect(() => {
    if (isUserStorePersisted && hasPreferencesLoaded) {
      const { user, meta } = store.getState();
      if (meta?.preferences?.migratePreferences) {
        migratePreferences(user);
      }
      /* for testing */
      // const params: any = getQueryParams(window.location.href, true);
      // if (params.reset) {
      //   resetUserPreferences();
      // }
      /* */
      loadLanguage();
    }
  }, [isUserStorePersisted, hasPreferencesLoaded]); // isUserStorePersisted by if userId store is empty

  const isRenderInternalPage = useMemo(() => {
    return !authState.ui?.isError && authState.data?.accessToken && particulars && isLanguageLoaded;
  }, [authState.ui?.isError, authState.data?.accessToken, particulars, isLanguageLoaded]);

  console.log('LANG - isRender,part,loadedlang, userpersist,prefsloaded', isRenderInternalPage, particulars, isLanguageLoaded, isUserStorePersisted, hasPreferencesLoaded);
  // Only load the main content when we have received the token, retrieved the user particulars and loaded the language
  // Language must be loaded before the main content is shown as Graphene components will not re-render with an updated locale.
  return (
    <Suspense fallback={null}>
      {appAllowed === true ? (
        <Router>
          <ScrollToTop />
          <ClickInterceptor>
            {isRenderInternalPage && (
              <>
                <PageVisibility onChange={performSessionCheckOnVisible} />
                {routes(t)}
              </>
            )}
            <Suspense fallback={null}>
              {showRequireUpdate && <RequiredUpdate />}
              {showNonRequireUpdate && <NonRequiredUpdate onDismiss={(): void => handleDismiss()} />}
              <SessionTimeoutDialog isActive={!showLogin} />
              {(authState.ui?.isError || showLogin) &&
                !showRequireUpdate && !showNonRequireUpdate && <Login title={t('pages.titles.login')} />}
            </Suspense>
          </ClickInterceptor>
        </Router>
      ) : (
        <AppBlocker />
      )}
    </Suspense>
  );
}

export default App;
